<template>
  <div v-if="articleData">
    <div class="border-b p-3 rounded-t bg-gray-100">
      <h3 class="text-lg text-gray-900">
        {{ articleData.uuid ? 'Modification' : 'Création' }} d'un article
      </h3>
    </div>
    <div class="space-y-6 bg-white sm:p-6">
      <t-input-group label="Titre">
        <t-input
          v-model="articleData.title"
          type="text"
          placeholder="Titre de l'article"
        />
      </t-input-group>

      <t-input-group label="Contenu de l'article">
        <quill-editor
          ref="myQuillEditor"
          v-model="articleData.body"
          :options="quillOptions"
        />
        <p class="text-sm text-gray-500">
          Pour ajouter un lien vers une ressource, veuillez utiliser cette syntaxe dans le texte du lien:
          <b>/ressource/identifiant-unique</b>.<br />
          Exemples: <b>/article/123</b> ou <b>/question/456</b> ou <b>/sondage/789</b><br />
          (L'identifiant unique se trouve dans les détails de la ressource)
        </p>
      </t-input-group>

      <div
        v-if="showCropper"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50 -top-11"
        @click.self="cancelCrop"
      >
        <div class="bg-gray-200 p-6 rounded-xl shadow-xl overflow-hidden max-w-lg w-full">
          <div class="overflow-hidden">
            <img
              ref="imageToCrop"
              :src="imageSrc"
              class="block mx-auto object-contain"
            />
          </div>
          <div class="flex justify-end space-x-4 mt-4">
            <t-button
              variant="error"
              @click="cancelCrop"
            >
              Annuler
            </t-button>
            <t-button
              @click="applyCrop"
            >
              Insérer
            </t-button>
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-2"
        :class="{ 'grid-cols-2 gap-3': usersGroups }"
      >
        <div>
          <t-input-group label="Mots-clés">
            <vue-tags-input
              v-model="currentKeyword"
              :tags="articleKeywords"
              @tags-changed="updateTags"
              :separators="[';', ',']"
              allow-edit-tags
              class="w-full max-w-none"
            />
          </t-input-group>
        </div>
        <div v-if="usersGroups">
          <t-input-group label="Groupes (facultatif)">
            <t-rich-select
              multiple
              :close-on-select="false"
              :options="usersGroups"
              placeholder="Sélectionner des groupes"
              v-model="groupsSelected"
            ></t-rich-select>
          </t-input-group>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-3">
        <t-input-group
          label="Illustration"
          feedback="(image carrée, 1000x1000 maximum)"
        >
          <div>
            <div class="">
              <div v-if="thumbnailUrl">
                <img
                  :src="thumbnailUrl"
                  width="256"
                  height="256"
                />
                <button
                  @click="thumbnailUrl = ''"
                  class="text-red-500"
                >Supprimer</button>
              </div>
              <div v-else-if="articleData.thumbnail">
                <img :src="storageUrl + '/thumbnails/' + articleData.thumbnail" />
                <button
                  @click="removeThumbnail"
                  class="text-red-500"
                >Supprimer</button>
              </div>
            </div>
            <file-upload
              extensions="gif,jpg,jpeg,png"
              accept="image/*"
              :maximum="1"
              :multiple="false"
              v-model="files"
              @input-filter="inputFilter"
              @input-file="inputThumbnail"
              ref="upload"
              input-id="thumbnail"
              class="bg-blue-400 p-1.5 rounded-md text-white ml-auto mt-7 cursor-pointer"
            >
              <span v-if="!this.articleData.thumbnail && !thumbnailUrl">
                Choisir une miniature
              </span>
              <span v-if="this.articleData.thumbnail">
                ✎ Modifier miniature
              </span>
            </file-upload>
          </div>
        </t-input-group>

        <t-input-group label="Documents">
          <div>
            <ul>
              <li
                v-for="doc in this.articleData.documents"
                :key="doc.id"
                class="flex justify-between pr-3"
              >
                <span>{{ doc.name }}</span>

                <span @click="deleteFile(doc)">
                  <i class="fa fa-trash text-red-500 cursor-pointer"></i>
                </span>
              </li>
            </ul>
            <div class="flex">
              <file-upload
                extensions="pdf,doc,docx,xls,xlsx"
                accept=".pdf,.doc,.docx,.xls,.xlsx"
                :multiple="true"
                v-model="documents"
                @input-filter="inputFilter"
                @input-file="inputDocumentFile"
                ref="documentUpload"
                input-id="documents"
                class="bg-blue-400 p-1.5 rounded-md text-white ml-auto mt-7"
              >
                <i class="fa fa-plus" /> Ajouter document(s)
              </file-upload>
            </div>
            <ul v-if="articleDocuments.length">
              <li
                v-for="doc in articleDocuments"
                :key="doc.id"
              >
                {{ doc.name }}
              </li>
            </ul>
          </div>
        </t-input-group>
      </div>
      <t-input-group
        v-if="this.domain.slug === 'media-mvp'"
        label="Thème"
      >
        <t-rich-select
          v-model="articleData.theme"
          :options="themes"
          placeholder="Sélectionner un thème"
        />
      </t-input-group>
      <t-input-group label="Date de publication">
        <t-datepicker
          timepicker
          v-model="articleData.publication_date"
          date-format="Y-m-d H:i:S"
          user-format="j F, Y H:i"
          placeholder="Cliquez pour sélectionner la date de publication..."
          :locale="locale"
          clearable
          required
          inline
          :disabled="articleData.is_notified == 1"
          :disabled-dates="articleData.is_notified == 1 ? () => true : null"
        />
      </t-input-group>

    </div>

    <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-between border-t border-t-gray-300">
      <div class="flex gap-3 items-center">
        <label for="active">Actif</label>
        <t-toggle
          class="ml"
          v-model="articleData.is_enabled"
          :value="true"
          :uncheckedValue="false"
        />
        <div
          v-if="domain.can_avoid_notifications && !articleData.uuid"
          class="ml-8 flex gap-3 items-center"
        >
          <label>Ne pas notifier</label>
          <t-toggle
            class=""
            v-model="articleData.to_notify"
            :value="false"
            :uncheckedValue="true"
          />
        </div>
      </div>
      <div class="flex gap-3">
        <t-button
          v-if="articleData.uuid"
          class="m-0"
          @click.prevent="notifyArticle"
          variant="secondary"
        >
          Renotifier
        </t-button>
        <t-button
          v-if="articleData.uuid && hasDuplicationRights"
          @click.prevent="duplicateArticle"
          variant="secondary"
        >
          Dupliquer
        </t-button>
        <!-- <t-button
          v-if="articleData.uuid"
          class="m-0"
          @click.prevent="deleteArticle"
          variant="error"
        >
          Supprimer
        </t-button> -->
        <t-button
          @click.prevent="saveArticle"
          variant="success"
        >
          Sauvegarder
        </t-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import VueTagsInput from '@johmun/vue-tags-input'
import { quillEditor } from 'vue-quill-editor'

import FileUpload from 'vue-upload-component'

import { http } from '@/services/api.service'
import { format } from 'date-fns'
import French from 'vue-tailwind/dist/l10n/fr'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent.vue'
import { formatWithOptions } from 'date-fns/fp'

import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { mapGetters } from 'vuex'


export default {
  name: 'mvp-articles-form',
  components: {
    PageHeader,
    PageContent,
    quillEditor,
    FileUpload,
    VueTagsInput
  },
  computed: {
    ...mapGetters([
      'domain',
      'roles'
    ]),
    hasDuplicationRights () {
      return this.roles.some(role => ['admin', 'director', 'community-manager'].includes(role))
    }
  },
  data() {
    return {
      upload: null,
      showCropper: false,
      imageSrc: '',
      cropper: null,
      isEditorImage: false,
      quillOptions: {
        modules: {
          toolbar: {
        container: [
          ['bold', 'italic'],
          [{ list: 'bullet' }],
          ['link', 'image', 'video']
        ],
        handlers: {
          image: this.handleImageInsertion
        }
          }
        }
      },
      themes: [
        { value: 'Juridique', text: 'Juridique' },
        { value: 'Société', text: 'Société' },
        { value: 'Dialogue social', text: 'Dialogue social' },
        { value: 'Économie', text: 'Économie' }
      ],
      articleData: {
        publication_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        groups: [],
        theme: null,
        to_notify: true,
      },
      articleFiles: [],
      articleUniqueKey: null,
      currentKeyword: '',
      articleKeywords: [],
      thumbnailFile: null,
      thumbnailUrl: '',
      document_file: null,
      thumbnail: null,
      files: [],
      storageUrl: process.env.VUE_APP_STORAGE_URL,
      filesToAdd: [],
      groupsSelected: [],
      locale: French,
      documents: [],
      articleDocuments: [],
      usersGroups: []
    }
  },
  props: [
    'article',
    'articleKey',
    'groups'
  ],
  beforeMount: async function () {
    this.articleUniqueKey = typeof this.$route.params.uuid !== 'undefined'
      ? this.$route.params.uuid : this.articleKey

    this.groups.forEach(group => {
      this.usersGroups.push({
        value: group.uuid,
        text: group.label
      })
    })
    // article loading
    if (typeof this.articleUniqueKey !== 'undefined') {
      await http.get(`/article/${this.articleUniqueKey}`)
        .then(({ data }) => {
          this.articleData = data
          this.parseArticle()
        })
        .catch(err => {
          console.log(err)
          if (err && err.response.status === 404) {
            this.$notify({
              group: 'maviepro-error',
              text: 'Article introuvable.'
            })
          } else {
            this.$notify({
              group: 'maviepro-error',
              text: 'Impossible de récupérer l\'article.'
            })
          }
          this.$parent.$modal.hide('user-form')
        })
    }

  },
  methods: {
    parseArticle() {
      this.articleKeywords = []
      if (this.articleData.keywords) {
        this.articleData.keywords.forEach(keyword => {
          this.articleKeywords.push({
            text: keyword
          })
        })
      }

      if (this.articleData.notifications) {
        this.articleData.notifications.forEach(notification => {
          this.groupsSelected.push(notification.group.uuid)
        })
      }

      if (this.articleData.files) {
        this.articleData.files.forEach(file => {
          this.articleFiles.push({
            source: file.url,
            options: {
              type: 'local',
              file: {
                name: file.name,
                size: file.size
              },
              metadata: {
                uuid: file.uuid
              }
            }
          })
        })
      }
    },

    parser(date) {
      return parse(date)
    },
    getImgUrl() {
      return `${this.storageUrl}/thumbnails/${this.articleData.thumbnail}`
    },
    saveArticle() {
      const formData = new FormData()
      const isUpdate = !!this.articleData.uuid
      console.log('isUpdate', isUpdate)
      const { thumbnail_file, ...data } = this.articleData
      const postUrl = '/article' + (isUpdate ? `/${this.articleData.uuid}` : '')
      const currentStatus = this.$route.query.status
      for (const prop in data) {
        formData.append(prop, data[prop])
      }

      // Append keywords
      formData.delete('keywords')
      if (this.articleData.keywords && this.articleData.keywords.length) {
        this.articleData.keywords.forEach(keyword => {
          formData.append('keywords[]', keyword)
        })
      }

      // Append group UUIDs
      this.groupsSelected.forEach(groupUuid => {
        formData.append('groupUuids[]', groupUuid)
      })

      // Handling files and thumbnail for new article
      if (!isUpdate) {
        if (this.thumbnail) {
          formData.append('thumbnail', this.thumbnail)
        }
        this.files.map(file => {
          formData.append('files[]', file)
        })
      }

      http.post(postUrl, formData).then(res => {
        this.$emit('refresh')
        this.$parent.$modal.hide('article-form')
        this.$notify({
          group: 'maviepro-success',
          text: 'Article mis à jour.'
        })
        if (currentStatus) {
          this.$router.replace({
            name: 'app-articles',
            query: { status: currentStatus }
          })
        } 
      }).catch(err => {
        console.log(err)
        this.$notify({
          group: 'maviepro-error',
          text: 'Erreur lors de la sauvegarde de l\'article.'
        })
      })
    },
    deleteArticle() {
      this.$dialog.confirm({
        title: 'Supprimer cet article ?',
        text: 'Cette action ne peut pas être annulée.',
        icon: 'warning',
        cancelButtonText: 'Annuler',
        okButtonText: 'Supprimer'
      }).then((result) => {
        if (result.isOk && result.hideReason === 'ok') {
          http.delete(`/article/${this.articleUniqueKey}`).then(res => {
            this.$notify({
              group: 'maviepro-success',
              text: 'Article supprimé.'
            })
            this.$emit('refresh')
            this.$parent.$modal.hide('article-form')
          }).catch(err => {
            console.log(err)
            this.$notify({
              group: 'maviepro-error',
              text: 'Problème lors de la suppression, veuillez contacter un administrateur.'
            })
          })
        }
      })
    },
    notifyArticle() {
      this.$buefy.dialog.confirm({
        title: 'Notification',
        message: `Êtes vous sur de vouloir renotifier cet article ?`,
        cancelText: 'Annuler',
        confirmText: 'Renotifier',
        type: 'is-danger',
        onConfirm: () => {
          http.post(`/article/${this.articleData.uuid}/notify`).then(res => {
            this.$notify({
              group: 'maviepro-success',
              text: 'Notification envoyée.'
            })
            this.$parent.$modal.hide('article-form')
          }).catch(err => {
            console.log(err)
            this.$notify({
              group: 'maviepro-error',
              text: 'Erreur lors de l\'envoi de la notification.'
            })
          })
        }
      });
    },
    duplicateArticle () {
      if (!this.articleData.uuid) {
        this.$notify({
          group: 'maviepro-error',
          text: 'Impossible de dupliquer un article non sauvegardé.'
        })
        return
      }

      this.$buefy.dialog.confirm({
        title: 'Duplication de l\'article',
        message: 'Voulez-vous vraiment dupliquer cet article ?<br><br>En confirmant, l\'article sera automatiquement dupliqué sur votre domaine dans l\'onglet "Actualités inactives"',
        confirmText: 'Dupliquer',
        cancelText: 'Annuler',
        type: 'is-info',
        hasIcon: true,
        icon: 'copy',
        onConfirm: () => {
          this.loading = true
          const duplicationData = {
            domain: this.domain
          }
          http.post(`/article/${this.articleData.uuid}/duplicate`, duplicationData)
            .then(res => {
              this.loading = false
              this.$notify({
                group: 'maviepro-success',
                text: 'Article dupliqué avec succès.'
              })
              this.$parent.$modal.hide('article-form')
            })
            .catch(err => {
              this.loading = false
              console.log(err)
              this.$notify({
                group: 'maviepro-error',
                text: 'Erreur lors de la duplication de l\'article.'
              })
            })
        }
      })
    },
    updateTags(tags) {
      this.articleData.keywords = tags.map(tag => tag.text)
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    // Thumbnail part
    inputThumbnail (newFile, oldFile) {
      this.isEditorImage = false
      if (newFile && !oldFile) {
        let reader = new FileReader()
        reader.readAsDataURL(newFile.file)
        reader.onload = (e) => {
          this.imageSrc = e.target.result
          this.showCropper = true
          this.$nextTick(() => {
            const image = this.$refs.imageToCrop
            this.cropper = new Cropper(image, {
              aspectRatio: 7/4,
              viewMode: 2,
              dragMode: 'crop',
              autoCropArea: 1,
              background: false,
              cropBoxMovable: true,
              cropBoxResizable: true,
              responsive: true
            })
          })
        }
      }
      if (!newFile && oldFile) {
        this.thumbnailUrl = ''
        if (!this.articleData.uuid) {
          return false
        }
        http.delete(`/article/${this.articleData.uuid}/thumbnail`).then(res => {
          this.thumbnailUrl = null
          this.thumbnail = null
          this.articleData.thumbnail_extension = null
        }).catch(err => {
          console.log(err)
          this.$notify({
            group: 'maviepro-error',
            text: 'Problème lors de la suppression de la miniature.'
          })
        })
      }
    },

    addThumbnail(error, file) {
      if (file && file.file) {
        this.thumbnail = file.file
      }
      // creation, fill only
      if (!this.articleData.uuid) {
        return false
      }
      if (error) return false
      // update: upload file now
      const formData = new FormData()
      if (file) {
        formData.append('thumbnail', this.thumbnail)
      }
      http.post(`/article/${this.articleData.uuid}/thumbnail`, formData).then(({ data }) => {
        this.articleData.thumbnail = data
      }).catch(err => {
        console.log(err)
      })
    },
    removeThumbnail() {
      http.delete(`/article/${this.articleData.uuid}/thumbnail`).then(({ data }) => {
        this.articleData.thumbnail = null
      }).catch(err => {
        console.log(err)
      })
    },
    // Documents part
    inputDocumentFile(newDoc, oldDoc) {
      if (newDoc && !oldDoc) {
        this.addFile(false, newDoc)

        let reader = new FileReader()
        reader.readAsDataURL(newDoc.file)
        reader.onload = (e) => {
          this.articleDocuments.push({
            name: newDoc.name,
            data: e.target.result
          })
        }
      }
      if (!newDoc && oldDoc) {
        let index = this.articleDocuments.findIndex(d => d.name === oldDoc.name)
        if (index !== -1) this.articleDocuments.splice(index, 1)
      }
    },
    addFile(error, file) {
      if (file && file.file) {
        this.files.push(file.file)
      }
      // creation, fill only
      if (!this.articleData.uuid) {
        return false
      }
      if (error) return false
      // update: upload file now
      const formData = new FormData()
      if (file && file.file) {
        formData.append('files[]', file.file)
      }
      http.post(`/article/${this.articleData.uuid}/file`, formData).then(({ data }) => {
        this.articleData.files = [...this.articleData.files, ...data]
        this.filesToAdd = []
      }).catch(err => {
        this.filesToAdd = []
        console.log(err)
      })
    },
    deleteFile(file) {
      if (!this.articleData.uuid) {
        return false
      }
      const fileUuid = file['uuid']
      http
        .delete(`/article/${this.articleData.uuid}/file/${fileUuid}`)
        .then(({ data }) => {
          this.articleData.documents = this.articleData.documents.filter(doc => doc.uuid !== fileUuid)
        }).catch(err => {
          console.log(err)
        })
    },

    handleImageInsertion () {
      this.isEditorImage = true
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = 'image/*'
      fileInput.onchange = (e) => {
        const file = e.target.files[0]
        if (file) {
          const reader = new FileReader()
          reader.onload = (event) => {
            this.imageSrc = event.target.result
            this.showCropper = true
            this.$nextTick(() => {
              const image = this.$refs.imageToCrop
              this.cropper = new Cropper(image, {
                viewMode: 2,
                dragMode: 'crop',
                autoCropArea: 1,
                background: false,
                cropBoxMovable: true,
                cropBoxResizable: true,
                responsive: true
              })
            })
          }
          reader.readAsDataURL(file)
        }
      }
      fileInput.click()
    },
    async applyCrop () {
      const canvas = this.cropper.getCroppedCanvas()
      const imageData = canvas.toDataURL('image/png')
      this.cropper.destroy()
      this.showCropper = false

      if (this.isEditorImage) {
        this.insertImageToEditor(imageData)
      } else {
        canvas.toBlob((blob) => {
          const croppedFile = new File([blob], 'cropped_thumbnail.png', { type: 'image/png' })
          this.thumbnailUrl = imageData
          this.thumbnail = croppedFile
          this.addThumbnail(false, { file: croppedFile })
        }, 'image/png')
      }
    },
    cancelCrop () {
      if (this.cropper) {
        this.cropper.destroy()
      }
      this.showCropper = false
      if (this.isEditorImage) {
      } else {
        this.thumbnailUrl = ''
      }
    },
    insertImageToEditor (image) {
      const quillEditor = this.$refs.myQuillEditor.quill
      const range = quillEditor.getSelection()
      quillEditor.insertEmbed(range.index, 'image', image)
    },
  }
}
</script>

<style
  lang="scss"
  scoped
>
.thumbnail-preview {
  width: 128px;
  height: 128px;
  box-shadow: 1px 2px 5px 0 #999;
  border-radius: 2px;
}

.file-name {
  border: 1px solid #999 !important;
}

.documents-list li .columns {
  display: flex;
  align-items: center;

  a:hover,
  .remove:hover {
    cursor: pointer;
  }
}

.thumbnail .field-body .img {
  position: relative;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  .remove {
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
    cursor: pointer;
  }
}
</style>
