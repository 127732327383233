<template>
  <div>
    <PageHeader
      :title="`Actualités > ${domain.label}`"
      description="Gestion des actualités"
      :button="{
        icon: 'fas fa-plus-circle',
        text: 'Ajouter un article',
        action: openModal
      }"
    />
    <NewsSubmenu />

    <PageContent>
      <div
        v-if="loading"
        class="fixed top-0 left-0 w-screen h-screen bg-white bg-opacity-50 z-50 cursor-wait"
        />
      <MVPTable
        :gridData="gridData"
        :options="gridOptions"
        @rowDoubleClicked="openModal"
      />
    </PageContent>

    <t-modal
      name="article-form"
      variant="contentForm"
    >
      <MVPArticlesForm
        v-if="currentArticle"
        :article="currentArticle"
        :articleKey="currentArticle.uuid"
        :groups="groups"
        @refresh="getArticles"
      />
    </t-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { http } from '@/services/api.service'
import { format, getISOWeek } from 'date-fns'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import MVPTable from '@/components/agGrid/MVPTable.vue'
import ListRowDetail from './ListRowDetail.vue'
import MVPArticlesForm from './Form.vue'
import NewsSubmenu from './NewsSubmenu.vue'

const getDocumentsCount = (params) => {
  return params.data.documents.length
}

export default {
  name: 'app-articles-list',
  components: {
    PageHeader,
    PageContent,
    NewsSubmenu,
    ListRowDetail, // eslint-disable-line
    MVPTable,
    MVPArticlesForm
  },
  computed: {
    ...mapGetters([
      'roles',
      'domain'
    ]),
    hasEditionRights () {
      return this.roles.some(role => ['admin', 'director', 'community-manager'].includes(role))
    },
    gridOptions () {
      return {
        masterDetail: true,
        detailCellRenderer: 'ListRowDetail',
        detailRowAutoHeight: true,
        rowDragManaged: true,
        onRowDragEnd: this.handleRowDragEnd,
        columnDefs: [
          {
            cellRenderer: 'agGroupCellRenderer',
            width: 45,
            suppressMenu: true,
            rowDrag: (params) => {
              if (!this.hasEditionRights) {
                return false
              }
              return params.node.data.is_pinned
            }
          },
          {
            headerName: 'Illustration',
            field: 'thumbnail',
            cellRenderer: 'ImageCellRenderer',
            cellRendererParams: {
              image: {
                classes: 'h-6 w-6',
                filename: 'thumbnail',
                path: 'thumbnails'
              }
            },
            suppressMenu: true,
            width: 40
          },
          {
            headerName: 'Titre',
            field: 'title',
            filter: 'agSetColumnFilter',
            sortable: true
          },
          {
            headerName: 'Date',
            field: 'publication_date',
            type: ['dateColumnFilterable'],
            cellRenderer: (params) => {
              if (!params.value) return ''
              const date = new Date(params.value)
              const weekNum = getISOWeek(date)
              return format(date, 'dd/MM/yyyy') + ` - S${weekNum} - ` + format(date, 'HH:mm')
            },
            sortable: true,
            width: 120
          },
          {
            headerName: 'Notifié',
            width: 80,
            field: 'is_notified',
            cellRenderer: 'TagCellRenderer',
            cellRendererParams: {
              tag: {
                labels: {
                  true: 'Notifiée',
                  false: 'En attente'
                }
              }
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              cellRenderer: 'TagFilterCellRenderer',
              cellRendererParams: {
                tag: {
                  labels: {
                    true: 'Notifiée',
                    false: 'En attente'
                  }
                },
                buttons: ['reset']
              }
            },
            sortable: true
          },
          {
            headerName: 'Lectures',
            type: 'numericColumn',
            field: 'consultations_count',
            filter: 'agNumberColumnFilter',
            width: 80,
            sortable: true
          },
          {
            headerName: 'PJ',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            valueGetter: getDocumentsCount,
            width: 80,
            sortable: true
          },
          {
            headerName: 'Avis',
            headerClass: 'ag-center-aligned-header',
            field: 'rating.percent',
            filter: 'agNumberColumnFilter',
            cellRenderer: 'RatingCellRenderer',
            width: 80,
            sortable: true
          },
          {
            headerName: 'Duplications',
            type: 'numericColumn',
            field: 'duplications_count',
            filter: 'agNumberColumnFilter',
            width: 80,
            sortable: true,
            hide: !['nosdroits', 'media-mvp'].includes(this.domain.slug)
          },
          {
            headerName: 'Épinglé',
            field: 'is_pinned',
            cellRenderer: 'agCheckboxCellRenderer',
            cellRendererParams: {
              disabled: !this.hasEditionRights
            },
            cellEditor: 'agCheckboxCellEditor',
            editable: true,
            width: 70,
            valueGetter: function (params) {
              return Boolean(params.data.is_pinned)
            },
            valueSetter: this.pinnedValueSetter,

            valueParser: function (params) {
              return params.newValue ? 1 : 0
            },
            hide: this.$route.query.status === 'inactive'
          },
          // {
          //   headerName: 'Position',
          //   field: 'pinned_position',
          //   width: 70,
          //   // sort: 'asc',
          //   rowDrag: (params) => {
          //     if (!this.hasEditionRights) {
          //       return false
          //     }
          //     return params.node.data.is_pinned
          //   },
          //   valueGetter: (params) => {
          //     return params.data.is_pinned ? params.data.pinned_position + 1 : null
          //   }
          // },
          {
            cellRenderer: 'IconCellRenderer',
            cellRendererParams: (params) => ({
              iconClass: 'fa-solid fa-circle-exclamation text-blue-600',
              onClick: () => this.followUpAssignee(params.data)
            }),
            width: 40,
            hide: !this.roles.some(role => ['admin', 'community-manager'].includes(role)) || this.$route.query.status === 'active'
          },
          {
            cellRenderer: 'IconCellRenderer',
            cellRendererParams: {
              iconClass: 'fas fa-trash text-red-600',
              onClick: this.deleteRow
            },
            width: 40,
            hide: !this.hasEditionRights
          }
        ]
      }
    },

    pinnedArticlesCount () {
      return this.gridData ? this.gridData.filter(article => article.is_pinned).length : 0
    }

  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_STORAGE_URL,
      loading: false,
      articles: [],
      articleKey: null,
      currentArticle: null,
      gridData: null,
      groups: [],
      stats: null,
      activeStatus: 'active'
    }
  },
  beforeMount: async function () {
    this.getGroups()
    if (this.$route.params.uuid) {
      this.articleKey = this.$route.params.uuid
      await http.get(`/article/${this.articleKey}`).then(res => {
        this.article = res.data
        this.articleKey = res.data.uuid
        this.openModal(this.article)
      }).catch(err => {
        let message
        if (err && err.response.status === 404) {
          message = 'L\'article demandé est introuvable'
        } else {
          message = 'Erreur de chargement de l\'article'
        }

        this.$buefy.notification.open({
          duration: 3000,
          message,
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    }
  },
  mounted () {
    this.getArticles()
  },
  watch: {
    domain (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getArticles()
        this.getGroups()
      }
    },
    '$route.query.status' (newStatus, oldStatus) {
      if (newStatus !== oldStatus) {
        this.getArticles()
      }
    }
  },
  methods: {
    pinnedValueSetter (params) {
      const parsedValue = params.newValue ? 1 : 0
      if (parsedValue === 1 && this.gridData.filter(article => article.is_pinned).length >= 6) {
        alert('Vous ne pouvez pas épingler plus de 6 articles, décochez un article épinglé pour en épingler un nouveau')
        this.getArticles()
        return false
      } else if (parsedValue !== params.oldValue) {
        this.loading = true
        params.data.is_pinned = parsedValue

        let postData = { is_pinned: parsedValue }

        if (parsedValue === 0) {
          postData.pinned_position = null
          http.post(`/article/${params.data.uuid}`, postData)
            .then(() => {
              const pinnedArticles = this.gridData.filter(article => article.is_pinned && article.uuid !== params.data.uuid)
              const updatePromises = pinnedArticles.map((article, index) => {
                article.pinned_position = index
                return http.post(`/article/${article.uuid}`, { pinned_position: article.pinned_position })
              })

              return Promise.all(updatePromises)
            })
            .then(() => {
              this.getArticles()
            })
            .catch(err => {
              console.log(err)
            })
        } else if (parsedValue === 1) {
          const pinnedPositions = this.gridData.map(article => article.pinned_position).filter(pos => pos !== null && pos !== undefined)

          let nextPinnedPosition
          if (pinnedPositions.length === 0) {
            nextPinnedPosition = 0
          } else {
            nextPinnedPosition = Math.max(...pinnedPositions) + 1
          }

          postData.pinned_position = nextPinnedPosition

          http.post(`/article/${params.data.uuid}`, postData)
            .then(() => {
              this.getArticles()
            })
            .catch(err => {
              console.log(err)
            })
        }
        return true
      }
      return false
    },

    async getArticles () {
      this.loading = true
      const status = this.$route.query.status || 'active'
      await http
        .get('/article', {
          params: {
            sort: 'pinned_position',
            status: status
          }
        })
        .then((res) => {
          this.gridData = res.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          this.$buefy.notification.open({
            duration: 3000,
            message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des articles',
            position: 'is-bottom-left',
            type: 'is-warning',
            hasIcon: true,
            icon: 'server'
          })
        })
    },

    getGroups () {
      http
        .get('/groups')
        .then(res => {
          this.groups = res.data
        })
    },

    getDuplicationStatistics () {
      http.get('/articles/stats/duplications')
        .then(res => {
          this.stats = res.data
          console.log(this.stats)
        }).catch(err => console.log(err))
    },
    openModal ({ data }) {
      if (!this.hasEditionRights) {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Vous n\'avez pas les droits pour modifier un article',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })
        return
      }
      if (data) {
        this.currentArticle = data
      } else {
        this.currentArticle = {
          publication_date: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        }
      }
      this.$modal.show('article-form', data)
    },

    followUpAssignee (article) {
      if (article.followed_up === 1) {
        this.$notify({
          group: 'maviepro-error',
          text: 'Le rappel a déjà été envoyé'
        })
        return
      }
      http.post(`/article/${article.uuid}/followup`)
        .then(() => {
          article.followed_up = 1
          this.$notify({
            group: 'maviepro-success',
            text: 'Rappel envoyé'
          })
        })
        .catch(() => {
          this.$notify({
            group: 'maviepro-error',
            text: 'Problème lors de l\'envoi du rappel'
          })
        })
    },
    deleteRow (rowData) {
      // if (!this.roles.some(role => ['admin', 'director', 'community-manager'].includes(role))) {
      //   this.$buefy.notification.open({
      //     duration: 3000,
      //     message: 'Vous n\'avez pas les droits pour supprimer un évènement',
      //     position: 'is-bottom-right',
      //     type: 'is-danger',
      //     hasIcon: false
      //   })
      //   return
      // }
      if (confirm(`Êtes-vous sûr de vouloir supprimer cette actualité : ""${rowData.title}"" ?`)) {
        this.loading = true
        http.delete(`/articles/${rowData.uuid}`).then(res => {
          this.loading = false
          this.gridData = this.gridData.filter(item => {
            return item.uuid !== rowData.uuid
          })
          this.$buefy.notification.open({
            duration: 3000,
            message: `L'actualité "<strong>${rowData.title}</strong>" a bien été supprimé`,
            position: 'is-bottom-right',
            type: 'is-success',
            hasIcon: false
          })
        })
      } else {
        this.$buefy.notification.open({
          duration: 3000,
          message: `Annulation de la suppression de l'actualité ""<strong>${rowData.title}</strong>""`,
          position: 'is-bottom-right',
          type: 'is-warning',
          hasIcon: false
        })
      }
    },

    handleRowDragEnd (event) {
      this.loading = true

      let newIndex = event.overIndex
      const articleId = event.node.data.uuid

      const maxPinnedIndex = this.gridData.filter(article => article.is_pinned).length - 1

      if (newIndex > maxPinnedIndex) {
        newIndex = maxPinnedIndex
      }

      this.updatePinnedPosition(articleId, newIndex)

      const pinnedArticles = this.gridData.filter(article => article.is_pinned && article.uuid !== articleId)
      pinnedArticles.forEach((article, index) => {
        if (index >= newIndex) index += 1
        this.updatePinnedPosition(article.uuid, index)
      })
    },

    updatePinnedPosition (articleId, newIndex) {
      http.post(`/article/${articleId}`, {
        pinned_position: newIndex
      })
        .then(res => {
          this.getArticles()
        })
        .catch(err => {
          this.loading = false
          console.error(err)
        })
    }
  }
}
</script>
