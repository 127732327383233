<template>
  <div class="bg-white modal-card rounded" style="width: 50vw">
    <div class="bg-primary-100 p-4 text-xl">Créer un {{ roles[role] }}</div>
    <div class="grid grid-cols-4 overflow-y-auto p-4">
      <Form-create
        class="col-span-4"
        v-for="(user, i) of users"
        :user="user"
        :userIndex="i"
        @update="({ label, value }) => users[i][label] = value"
        :key="`user-${i}`"
        :isLast="i === users.length - 1"
        :isFirst="i === 0"
        @addUser="addUser"
        @removeUser="() => removeUser(i)"
        @error="$event => errors[i] = $event"
        :groups="groups"
      />
    </div>
    <div class="bg-gray-50 px-4 py-3 flex gap-3 justify-end">
      <t-button
        class="m-0"
        variant="secondary"
        @click="$parent.close()"
      >
        Annuler
      </t-button>
      <t-button @click.prevent="create">Créer</t-button>
    </div>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import FormCreate from '../FormCreate.vue'

export default {
  name: 'app-questions-create',
  components: { FormCreate },
  props: ['role'],
  data () {
    return {
      users: [{
        email: '',
        firstname: '',
        lastname: ''
      }],
      groups: [],
      errors: [{}],
      roles: {
        'user': 'utilisateur',
        'operator': 'opérateur',
        'director': 'administrateur'
      }
    }
  },
  created () {
    this.loadGroups()
  },
  methods: {
    async create () {
      for (let user of this.users) {
        try {
          const payload = {
            ...user,
            groups: user.groupsSelected
          }
          const res = await http.post(`/${this.role}`, payload)
          this.$emit('created', res.data)
        } catch (err) {
          this.$buefy.notification.open({
            duration: 3000,
            message: err.response.data.message,
            position: 'is-bottom-left',
            type: 'is-warning',
            hasIcon: true,
            icon: 'server'
          })
        }
      }
      this.$parent.close()
    },
    addUser () {
      this.users.push({
        email: null,
        firstname: null,
        lastname: null
      })
      this.errors.push({})
    },
    removeUser (index) {
      this.users.splice(index, 1)
      this.errors.splice(index, 1)
    },
    loadGroups () {
      http.get('/groups').then((res) => {
        this.groups = res.data
      })
    }
  }
}
</script>
